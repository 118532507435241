import gql from "graphql-tag";

import generalImages from "./fragments/general/generalImages.graphql";
import generalLinks from "./fragments/general/generalLinks.graphql";
import generalFiles from "./fragments/general/generalFiles.graphql";
import generalTexts from "./fragments/general/generalTexts.graphql";
import generalTextsWYSIWYG from "./fragments/general/generalTextsWYSIWYG.graphql";
import generalSeo from "./fragments/general/generalSeo.graphql";

import iframe from "./fragments/iframe.graphql";
import header from "./fragments/header.graphql";
import teaser from "./fragments/teaser.graphql";
import course from "./fragments/course.graphql";
import greenfee from "./fragments/greenfee.graphql";
import colsConnector from "./fragments/colsConnector.graphql";
import formType from "./fragments/formType.graphql";
import gallery from "./fragments/gallery.graphql";
import googleMaps from "./fragments/googleMaps.graphql";
import listing from "./fragments/listing.graphql";
import partner from "./fragments/partner.graphql";
import asset from "./fragments/asset.graphql";
import team from "./fragments/team.graphql";
import table from "./fragments/table.graphql";
import teamMember from "./fragments/teamMember.graphql";
import page from "./fragments/page.graphql";
import hole from "./fragments/hole.graphql";
import simplePage from "./fragments/pageTemplate/simplePage.graphql";
import trainer from "./fragments/trainer.graphql";
import lesson from "./fragments/lesson.graphql";
import modal from "./fragments/modal.graphql";
import courseInformation from "./fragments/courseInformation.graphql";
import images from "./fragments/images.graphql";
import partnerClub from "./fragments/partnerClub.graphql";
import contactWrapper from "./fragments/contactWrapper.graphql";

export const GET_PAGE = gql`
    ${generalImages}
    ${generalFiles}
    ${generalLinks}
    ${generalTexts}
    ${generalTextsWYSIWYG}
    ${generalSeo}

    ${colsConnector}
    ${course}
    ${formType}
    ${gallery}
    ${modal}
    ${googleMaps}
    ${greenfee}
    ${header}
    ${iframe}
    ${teaser}
    ${listing}
    ${partner}
    ${asset}
    ${team}
    ${teamMember}
    ${page}
    ${hole}
    ${images}
    ${table}
    ${simplePage}
    ${trainer}
    ${lesson}
    ${courseInformation}
    ${partnerClub}
    ${contactWrapper}

    query ($filter: String) {
        getPageListing(first: 1, filter: $filter, defaultLanguage: "de") {
            edges {
                node {
                    id
                    name
                    url
                    seo {
                        ...generalSeo
                    }
                    component {
                        metadata {
                            name
                            value
                        }
                        element {
                            ...colsConnector
                            ...course
                            ...header
                            ...iframe
                            ...teaser
                            ...gallery
                            ...modal
                            ...googleMaps
                            ...greenfee
                            ...formType
                            ...listing
                            ...table
                            ...simplePage
                            ...courseInformation
                            ...contactWrapper
                        }
                    }
                    links {
                        ...generalLinks
                    }
                }
            }
        }
    }
`;
